let env;
let cloudFrontURL;
let hostUrl;
let apiUrl;
const hostname = window && window.location && window.location.hostname;

switch (hostname) {
    case 'explore.engage.toyota.com':
        env = 'prod';
        break;
    case 'staging.explore.engage.toyota.com':
        env = 'staging';
        break;
    case 'test.explore.nonprod.engage.toyota.com':
        env = 'test';
        break;
    case 'dev.explore.nonprod.engage.toyota.com':
        env = 'dev';
        break;
    default:
        env = 'local';
        break;
}

switch (env) {
    case 'prod':
        hostUrl = 'https://explore.engage.toyota.com';
        cloudFrontURL = 'https://engage.toyota.com';
        apiUrl = 'https://engage.toyota.com/engageSharedApi/quickStartGuide';
        break;
    case 'staging':
        hostUrl = 'https://staging.explore.engage.toyota.com';
        cloudFrontURL = 'https://staging.engage.toyota.com';
        apiUrl = 'https://staging.engage.toyota.com/engageSharedApi/quickStartGuide';
        break;
    case 'test':
        hostUrl = 'https://test.explore.nonprod.engage.toyota.com';
        cloudFrontURL = 'https://test.nonprod.engage.toyota.com';
        apiUrl = 'https://test.nonprod.engage.toyota.com/engageSharedApi/quickStartGuide';
        break;
    case 'dev':
        hostUrl = 'https://dev.explore.nonprod.engage.toyota.com';
        cloudFrontURL = 'https://dev.nonprod.engage.toyota.com';
        apiUrl = 'https://dev.nonprod.engage.toyota.com/engageSharedApi/quickStartGuide';
        break;
    case 'local':
        hostUrl = 'https://localhost:3000';
        cloudFrontURL = 'https://dev.nonprod.engage.toyota.com';
        apiUrl = 'https://dev.nonprod.engage.toyota.com/engageSharedApi/quickStartGuide';
        break;
    default:
        throw new Error('Environment variable was not set correctly');
}

const variables = {
    apiURL: apiUrl,
    HostUrl: hostUrl,
    BasePath: '',
    AWSImgRepo: {
        cloudFrontURL,
        quickStartGuide: `${cloudFrontURL}/static/images/quickStartGuide/`
    }
}

export default variables;
