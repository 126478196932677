import React, { useState } from 'react';
import AppSettings from '../../../appsettings.js';

function ScrollToTopButton(props) {
    const [visible, setVisible] = useState(false);

    const imageSource = AppSettings.AWSImgRepo.quickStartGuide + 'scrollButton.svg';
    const imageSourceHover = AppSettings.AWSImgRepo.quickStartGuide + 'scrollButtonHover.svg';

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        setVisible(false);
    }

    function toggleVisible() {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true);
        }
        else if (scrolled <= 300) {
            setVisible(false);
        }
    }

    window.addEventListener('scroll', toggleVisible);

    const style = {
        height: '75px',
        position: 'fixed',
        top: '80%',
        cursor: 'pointer',
        zIndex: '2',
        visibility: visible ? 'visible' : 'hidden'
    };

    switch (props.device) {
        case 'mobile':
            style.marginLeft = '287px';
            break;
        case 'tablet':
            style.marginLeft = '649px';
            break;
        default: //desktop
            style.marginLeft = '1320px';
            break;
    }

    return (
        <img
            className='scroll-to-top'
            alt=''
            onClick={scrollToTop}
            src={imageSource}
            onMouseEnter={e => (e.currentTarget.src = imageSourceHover)}
            onMouseOut={e => (e.currentTarget.src = imageSource)}
            style={style}
        />
    );
}

export default ScrollToTopButton;