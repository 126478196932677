/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import Text from '../subComponents/text.js';
import Image from '../subComponents/image.js';
import Button from '../subComponents/button.js';
import AppButtons from '../subComponents/appButtons';
import Video from '../subComponents/video.js';
import TwoColumn from './twoColumn.js';
import FourColumn from './fourColumn.js';
import '../../../styles/css/quickStartGuide/hero.css';
import AppSettings from '../../../appsettings.js';
import ReactFreezeframe from 'react-freezeframe';

function Hero(props) {
    const currentRef = props.refsArray.find(items => items.id === props.data.ref);

    const heroRef = useRef();
    const parallaxRef = useRef();
    const heroCallbackFunction = (entries) => {
        const [entry] = entries
        if (entry.isIntersecting) {
            currentRef.visible = true;
            checkChange();
            parallaxRef.current && (parallaxRef.current.style.paddingTop = 100 - (entry.intersectionRatio * 130) + 'px');
        } else {
            currentRef.visible = false;
            checkChange();
        }
    }

    const checkChange = () => {
        if (currentRef.visible !== currentRef.viewport) {
            currentRef.viewport = currentRef.visible;
            if (currentRef.visible) {
                currentRef.entry = performance.now();
            } else {
                currentRef.exit = performance.now();
            }
            if (currentRef.entry && currentRef.exit && (currentRef.exit - currentRef.entry >= 2000)) {
                props.analytics.moduleSubLoad(getAnalyticsData(props.data))
            }
        }
    }

    function getAnalyticsData(data) {
        return (
            {
                tagId: '36.3',
                appId: 'eShow',
                section: 'Quick Start Guide',
                module: 'Sub Page Load',
                label: props.data.content[0].text,
            }
        );
    }

    const buildThresholdList = () => {
        let thresholds = [];
        let numSteps = 100;
      
        for (let i=1.0; i<=numSteps; i++) {
            let ratio = i/numSteps;
            thresholds.push(ratio);
        }
      
        thresholds.push(0);
        return thresholds;
    }

    let options = {
        threshold: buildThresholdList()
    }

    useEffect(() => {
        const observer = new IntersectionObserver(heroCallbackFunction, options);
        if (heroRef.current) observer.observe(heroRef.current);

        return () => {
            if (heroRef.current) observer.unobserve(heroRef.current);
        }
    }, [heroRef, options]);

    let linkAnalyticsModule = props.data.content.find(subcomponent => subcomponent.ref.includes('T')) || false;

    function renderSubComponent(item) {
        let heroText = true;

        const subComponentProps = {
            data: item,
            key: item.ref,
            device: props.device,
            scrollTargetID: props.scrollTargetID,
            setScrollTargetID: props.setScrollTargetID,
            analyticsModule: props.data.content[0].text,
            linkAnalytics: ( linkAnalyticsModule.text ? linkAnalyticsModule.text : props.data.content[0].text),
            refsArray: props.refsArray,
        };
        switch (item.type) {
            case "text":
                return <Text {...subComponentProps} heroText={heroText} />
            case "image":
                if (!item.background) {
                    return <Image {...subComponentProps} />;
                }
                break;
            case "twoColumn": return <TwoColumn {...subComponentProps} />;
            case "button": return <Button {...subComponentProps} />;
            case "appButtons": return <AppButtons {...subComponentProps} />;
            case "video": return <Video {...subComponentProps} />;
            case "fourColumn": return <FourColumn {...subComponentProps} />;
            default: return;
        }
    }

    function getStyle() {
        const style = {}

        if (props.data.sideMargins) {
            switch (props.device) {
                case 'mobile': style.maxWidth = '315px'; break;
                case 'tablet': style.maxWidth = '646px'; break;
                default: style.maxWidth = '1316px'; break;
            }
        }

        switch (props.device) {
            case 'mobile':
                style.marginTop = props.data.mobileStyling.marginTop;
                style.paddingBottom = props.data.mobileStyling.marginBottom;
                style.backgroundPosition = props.data.mobileStyling.backgroundPosition;
                style.height = props.data.mobileStyling.height;
                break;
            case 'tablet':
                style.marginTop = props.data.tabletStyling.marginTop;
                style.paddingBottom = props.data.tabletStyling.marginBottom;
                style.backgroundPosition = props.data.tabletStyling.backgroundPosition;
                style.height = props.data.tabletStyling.height;
                break;
            default: //desktop
                style.marginTop = props.data.desktopStyling.marginTop;
                style.paddingBottom = props.data.desktopStyling.marginBottom;
                style.backgroundPosition = props.data.desktopStyling.backgroundPosition;
                style.height = props.data.desktopStyling.height;
                break;
        }

        style.backgroundColor = props.data.backgroundColor

        return style;
    }

    function getBottomDivider() {
        if (props.data.bottomDivider !== "Yes") return;

        let dividerWidth;
        switch (props.device) {
            case 'mobile': dividerWidth = '315px'; break;
            case 'tablet': dividerWidth = '646px'; break;
            default: dividerWidth = '1316px'; break;
        }

        return (
            <hr
                className='bottomDivider'
                style={{
                    margin: 'auto',
                    borderTop: '1px',
                    borderColor: '#707070',
                    width: dividerWidth,
                }}
            ></hr>
        );
    }

    

    
    function backgroundCheck(){
        for (let i = 0; i < props.data.content.length; i++) {
                if (props.data.content[i].type === 'image' && props.data.content[i].background) {
                   background = true;
                   switch (props.device) {
                        case 'mobile':
                            return AppSettings.AWSImgRepo.quickStartGuide + props.data.content[i].mobileStyling.image;
                            
                        case 'tablet':
                            // backgroundStyle.height = props.data.content[i].tabletStyling.maxHeight
                            return AppSettings.AWSImgRepo.quickStartGuide + props.data.content[i].tabletStyling.image;
                        
                        default:
                            backgroundStyle.height = props.data.content[i].desktopStyling.maxHeight
                            return AppSettings.AWSImgRepo.quickStartGuide + props.data.content[i].desktopStyling.image;
                    }
                }
        }
    }

    function gifCheck(backgroundImage){
        if(backgroundImage.includes('.gif')){
            return <ReactFreezeframe src={backgroundImage}/>

        }
        else{
            return <img src = {backgroundImage}/>
        }
    }

    let backgroundStyle = {}
    let background = false;
    let styling = getStyle();
    const backgroundImage = backgroundCheck();

    return (
        <div id = {props.data.ref} ref={heroRef}>
            <div className='hero scrollbar-hidden' ref={currentRef?currentRef.ref:null} style={styling}>
                {background && <div ref={ currentRef ? parallaxRef : null } style = {{height: 'auto'}}>
                    {styling.backgroundPosition == 'bottom' && <div className="imageBottomWrapper" style = {{position:'absolute', height: backgroundStyle.height, bottom: 0, maxWidth: styling.maxWidth, marginBottom: styling.marginBottom, zIndex: 0}}>
                        {gifCheck(backgroundImage)}
                        
                        </div>
                    }
                    {styling.backgroundPosition == 'top' && <div className="imageTopWrapper" style = {{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, maxWidth: styling.maxWidth, marginBottom: styling.marginBottom, zIndex: 0}}>
                        {gifCheck(backgroundImage)}
                        </div>
                    }
                    <div className = 'heroText' ref={ currentRef ? parallaxRef : null } style = {{ width: '100%', top: 0, zIndex: 1}}>
                        {props.data.content.map(item => renderSubComponent(item))}
                    </div>
                </div>
            }
                {!background && <div ref={ currentRef ? parallaxRef : null } style={{paddingTop: `0px`, height: `auto`}}>
                     {props.data.content.map(item => renderSubComponent(item))}
                    </div>
                }
                </div>
            {getBottomDivider()}
        </div>
    );


}

export default Hero;