/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import '../../../styles/css/quickStartGuide/text.css';
import applyMarkdown from '../../../util/applyMarkdown';
import * as analytics from '../quickStartGuide.analytics.js';

function Text(props) {
    const [isTextVisible, setTextVisible] = useState(false);
    const textDomRef = useRef();
    const options = { threshold: [0.01] };

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (props.data.animate === "animate-once") {
                    if (entry.isIntersecting) {
                        setTextVisible(entry.isIntersecting);
                    }
                } else {
                    setTextVisible(entry.isIntersecting);
                }
            });
        }, options);
        observer.observe(textDomRef.current);
        return () => observer.unobserve(textDomRef.current);
    }, []);

    let style = {
        margin: 'auto',
        justifyContent: props.data.justify,
        textAlign: props.data.align,
        color: props.data.color,
        fontFamily: getFontFamily(props.data),
    }

    if (props.device === 'mobile') {
        style.fontSize = props.data.mobileStyling.size;
        style.width = props.data.mobileStyling.width;
        style.marginTop = props.data.mobileStyling.marginTop;
        style.lineHeight = props.data.mobileStyling.lineHeight;
        style.maxWidth = '316px';
    } else if (props.device === 'tablet') {
        style.fontSize = props.data.tabletStyling.size;
        style.width = props.data.tabletStyling.width;
        style.marginTop = props.data.tabletStyling.marginTop;
        style.lineHeight = props.data.tabletStyling.lineHeight;
        style.maxWidth = '644px';
    } else {
        style.fontSize = props.data.desktopStyling.size;
        style.width = props.data.desktopStyling.width;
        style.marginTop = props.data.desktopStyling.marginTop;
        style.lineHeight = props.data.desktopStyling.lineHeight;
    }

    function getFontFamily(data) {
        const families = {
            light: 'ToyotaType-Light',
            bold: 'ToyotaType-Bold',
            semibold: 'ToyotaType-Semibold',
            black: 'ToyotaType-Black',
            regular: 'ToyotaType-Regular'
        }
        return families[data.font];
    }

    function getAnalyticsData() {
        return (
            {
                tagId: '36.3',
                appId: 'eShow',
                section: 'Quick Start Guide',
                module: (props.analyticsModule ? props.analyticsModule : props.linkAnalyticsModule).replace(/\*/g, ''),
                label: props.data.text + " CTA",
            }
        );
    }

    if (props.data.url) {
        return (
            <a
                href={props.data.url}
                style={{ textDecoration: 'none' }}
                onClick={() => { analytics.linkClick(getAnalyticsData()) }}
                target={props.data.url.includes('engage.toyota.com') ? '' : '_blank'}
            >
                <div className="text" style={style} ref={textDomRef} >
                    {applyMarkdown(props.data.text, props.data.boldFont)}
                </div>
            </a>
        );
    }

    if (props.heroText) {
        return (
            <div
                className={props.data.animate === 'none' ? 'text' : `text fade-in-text ${isTextVisible ? 'is-visible' : ''}`}
                style={style}
                ref={textDomRef}
            >
                {applyMarkdown(props.data.text, props.data.boldFont)}
            </div>
        );
    }

    return (
        <div className="text" style={style}>
            {applyMarkdown(props.data.text, props.data.boldFont)}
            <div
                className={props.data.animate === 'none' ? '' : `fade-in-text ${isTextVisible ? 'is-visible' : ''}`}
                ref={textDomRef}
            ></div>
        </div>
    );
}

export default Text;