import React from "react";
import "../../../styles/css/quickStartGuide/app.css";
import "../../../styles/css/quickStartGuide/fourColumn.css";
import "../../../styles/css/toyota-style-standards.css";
import Text from "../subComponents/text.js";
import Image from "../subComponents/image.js";
import Button from "../subComponents/button.js";

function FourColumn(props) {
    const currentRef = props.refsArray.find(items => items.id === props.data.ref);

    let style = {};
    let columnStyle = {};

    switch (props.device) {
        case "mobile":
            style.marginTop = props.data.mobileStyling.marginTop;
            style.marginBottom = props.data.mobileStyling.marginBottom;
            style.flexDirection = "column";
            columnStyle.alignSelf = "center";
            columnStyle.width = "245px";
            break;
        case "tablet":
            style.marginTop = props.data.tabletStyling.marginTop;
            style.marginBottom = props.data.tabletStyling.marginBottom;
            style.flexDirection = "column";
            columnStyle.alignSelf = "center";
            columnStyle.width = "430px";
            break;
        default: //desktop
            style.marginTop = props.data.desktopStyling.marginTop;
            style.marginBottom = props.data.desktopStyling.marginBottom;
            style.flexDirection = "row";
            columnStyle.width = "250px";
            break;
    }

    const getBottomDivider = () => {
        if (props.data.bottomDivider !== "Yes") return;

        let dividerWidth;
        switch (props.device) {
            case 'mobile': dividerWidth = '315px'; break;
            case 'tablet': dividerWidth = '646px'; break;
            default: dividerWidth = '1316px'; break;
        }

        return (
            <hr
                className='bottomDivider'
                style={{
                    margin: 'auto',
                    borderTop: '1px',
                    borderColor: '#707070',
                    width: dividerWidth,
                }}
            ></hr>
        );
    }

    const getDivider = () => {
        if (props.data.divider) {
            switch (props.device) {
                case "desktop":
                    return (
                        <div style={
                            {
                                backgroundColor: "#959595",
                                width: "2px",
                                borderRadius: "5px",
                                marginLeft: props.data.divider.desktopDivider.margin,
                                marginRight: props.data.divider.desktopDivider.margin,
                                minHeight: props.data.divider.desktopDivider.minHeight
                            }
                        } />
                    );
                case "tablet":
                    return (
                        <hr style={
                            {
                                marginTop: props.data.divider.tabletDivider.margin,
                                marginBottom: props.data.divider.tabletDivider.margin,
                                borderTop: "2px solid",
                                borderRadius: "5px",
                                width: "430px",
                                borderColor: "#959595",
                                minWidth: props.data.divider.tabletDivider.dividerWidth
                            }
                        }></hr>
                    );

                case "mobile":
                    return (
                        <hr style={
                            {
                                marginTop: props.data.divider.mobileDivider.margin,
                                marginBottom: props.data.divider.mobileDivider.margin,
                                borderTop: "2px solid",
                                borderRadius: "5px",
                                borderColor: "#959595",
                                width: "243px",
                                minWidth: props.data.divider.mobileDivider.dividerWidth
                            }
                        }></hr>
                    );
                default: return;

            }
        }
    }

    const renderColumn = (content) => {
        const subComponentProps = {
            data: content,
            key: content.ref,
            device: props.device,
            scrollTargetID: props.scrollTargetID,
            setScrollTargetID: props.setScrollTargetID,
            refsArray: props.refsArray,
        };
        switch (content.type) {
            case "text":
                return <Text {...subComponentProps} />;
            case "image":
                return <Image {...subComponentProps} />;
            case "button":
                return <Button {...subComponentProps} />;
                // case "video": return <Video {...subComponentProps} />;
            default:
                return;
        }
    };

    return (
        <>
            <div className="fourColumn" style={style} ref={currentRef ? currentRef.ref : null}>
                <div className="column" id="firstCol"
                    style={columnStyle}>
                    {
                        props.data.content.firstCol.content.map((pop) => renderColumn(pop))
                    } </div>
                {
                    getDivider()
                }
                <div className="column" id="secondCol"
                    style={columnStyle}>
                    {
                        props.data.content.secondCol.content.map((pop) => renderColumn(pop))
                    } </div>
                {
                    getDivider()
                }
                <div className="column" id="thirdCol"
                    style={columnStyle}>
                    {
                        props.data.content.thirdCol.content.map((pop) => renderColumn(pop))
                    } </div>
                {
                    getDivider()
                }
                <div className="column" id="fourthCol"
                    style={columnStyle}>
                    {
                        props.data.content.fourthCol.content.map((pop) => renderColumn(pop))
                    } </div>
            </div>
            {getBottomDivider()}
        </>
    );
}

export default FourColumn;
