import React from 'react';
import { compiler } from 'markdown-to-jsx';


function applyMarkdown(text, boldFont) {

  function getBoldFont(boldFont) {
    const families = {
        light: 'ToyotaType-Light',
        bold: 'ToyotaType-Bold',
        semibold: 'ToyotaType-Semibold',
        black: 'ToyotaType-Black',
        regular: 'ToyotaType-Regular'
    }
    return families[boldFont];
  }
  
  const customBold = ({ children }) => (
    <span style={{fontFamily: getBoldFont(boldFont)}}>{children}</span>
  );

  const options = {
    overrides: {
        strong: customBold,
    },
};

  if (text) {
    text = text.replace(/\^\(?SM\)?\^/g, '℠');
    text = text.replace(/\^\(?TM\)?\^/g, '™');
    text = text.replace(/\^\(?R\)?\^/g, '®');
    const textJSX = compiler(text, options);
    if (textJSX.type === 'ul') {
      return textJSX;
    }
    return <span>{textJSX.props.children}</span>;
  }
  return null;
}


export default applyMarkdown;
