import React from 'react';
import '../../../styles/css/quickStartGuide/button.css';
import * as analytics from '../quickStartGuide.analytics.js';

function Button(props) {
    const currentRef = props.data.sectionID ? props.refsArray.find(items => items.id === props.data.sectionID) : null;
    const style = {};
    const labelStyle = {};

    switch (props.device) {
        case 'mobile':
            labelStyle.fontSize = '16px';
            labelStyle.marginTop = '8px';
            style.height = '42px';
            style.width = props.data.mobileStyling.width;
            style.marginTop = props.data.mobileStyling.marginTop;
            break;
        case 'tablet':
            labelStyle.fontSize = '16px';
            labelStyle.marginTop = '8px';
            style.height = '42px';
            style.width = props.data.tabletStyling.width;
            style.marginTop = props.data.tabletStyling.marginTop;
            break;
        default: // 'desktop'
            labelStyle.fontSize = '24px';
            labelStyle.marginTop = '7px';
            style.height = '53px';
            style.width = props.data.desktopStyling.width;
            style.marginTop = props.data.desktopStyling.marginTop;
            break;
    }

    switch (props.data.align) {
        case 'left':
            style.marginLeft = '0';
            style.marginRight = 'auto';
            break;
        case 'right':
            style.marginLeft = 'auto';
            style.marginRight = '0';
            break;
        default:
            style.marginLeft = 'auto';
            style.marginRight = 'auto';
            break;
    }

    function handleClick() {
        currentRef.scroll();
        analytics.linkClick(getAnalyticsData(props.data));
    }

    function getAnalyticsData(data) {
        return (
            {
                tagId: '36.3',
                appId: 'eShow',
                section: 'Quick Start Guide',
                module: (props.analyticsModule ? props.analyticsModule : props.linkAnalyticsModule).replace(/\*/g, ''),
                label: data.text + " CTA",
            }
        );
    }

    if (props.data.hasOwnProperty('sectionID')) {
        return (
            <div className='button' onClick={handleClick} style={style}>
                <span style={labelStyle}>{props.data.text}</span>
            </div>
        );
    } else if (props.data.url.includes('engage.toyota.com')) {
        return (
            <a href={props.data.url} style={{ textDecoration: 'none' }} onClick={() => { analytics.linkClick(getAnalyticsData(props.data)) }}>
                <div className='button' style={style}>
                    <span style={labelStyle}>{props.data.text}</span>
                </div>
            </a>
        );
    } else {
        return (
            <a href={props.data.url} style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer' onClick={() => { analytics.linkClick(getAnalyticsData(props.data)) }}>
                <div className='button' style={style}>
                    <span style={labelStyle}>{props.data.text}</span>
                </div>
            </a>
        );
    }
}

export default Button;