/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from 'react';
import '../../../styles/css/quickStartGuide/app.css';
import '../../../styles/css/quickStartGuide/twoColumn.css';
import '../../../styles/css/toyota-style-standards.css';
import Text from '../subComponents/text.js';
import Image from '../subComponents/image.js';
import Button from '../subComponents/button.js';
import AppButtons from '../subComponents/appButtons';
import Video from '../subComponents/video.js';

function TwoColumn(props) {
  const currentRef = props.refsArray.find(items => items.id === props.data.ref);
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();
  const options = {
    threshold: [0.15]
  };

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if(props.data.animate === "animate-once"){
          if (entry.isIntersecting) {
            setVisible(entry.isIntersecting);
          }
        }else{
          setVisible(entry.isIntersecting);
        }
      });
  }, options);
  observer.observe(domRef.current);
  return () => observer.unobserve(domRef.current);
}, []);

  let leftStyle = {};
  let rightStyle = {};
  let twoColumnStyle = {
    alignItems: "stretch",
    marginTop: ""
  };

  switch (props.device) {
    case 'mobile':
      twoColumnStyle.flexDirection = props.data.flexDirection.mobile;
      props.data.mobileStyling ? (twoColumnStyle.marginTop = props.data.mobileStyling.marginTop) : (twoColumnStyle.marginTop = "0px");
      props.data.mobileStyling ? (twoColumnStyle.paddingBottom = props.data.mobileStyling.marginBottom) : (twoColumnStyle.paddingBottom = "0px");
      if (props.data.flexDirection.mobile === 'row') {
        rightStyle.maxWidth = '158px';
        rightStyle.width = '158px';
        rightStyle.paddingLeft = props.data.content.right.mobileColumnStyling.centerMargin;
        leftStyle.maxWidth = '158px';
        leftStyle.width = '158px';
        leftStyle.paddingRight = props.data.content.left.mobileColumnStyling.centerMargin;
      } else {
        rightStyle.marginLeft = 'auto';
        rightStyle.marginRight = 'auto';
        rightStyle.maxWidth = '315px';
        rightStyle.width = '315px';
        leftStyle.marginLeft = 'auto';
        leftStyle.marginRight = 'auto';
        leftStyle.maxWidth = '315px';
        leftStyle.width = '315px';
      }
      if (props.data.flexDirection.mobile === 'column') {
        leftStyle.marginBottom = props.data.content.left.mobileColumnStyling.centerMargin;
        rightStyle.marginTop = props.data.content.left.mobileColumnStyling.centerMargin;
      } else if (props.data.flexDirection.mobile === 'column-reverse') {
        rightStyle.marginBottom = props.data.content.left.mobileColumnStyling.centerMargin;
        leftStyle.marginTop = props.data.content.left.mobileColumnStyling.centerMargin;
      }
      break;
    case 'tablet':
      twoColumnStyle.flexDirection = props.data.flexDirection.tablet;
      props.data.tabletStyling ? (twoColumnStyle.marginTop = props.data.tabletStyling.marginTop) : (twoColumnStyle.marginTop = "0px");
      props.data.tabletStyling ? (twoColumnStyle.paddingBottom = props.data.tabletStyling.marginBottom) : (twoColumnStyle.paddingBottom = "0px");
      if (props.data.flexDirection.tablet === 'row') {
        rightStyle.maxWidth = '322px';
        rightStyle.width = '322px';
        rightStyle.paddingLeft = props.data.content.right.tabletColumnStyling.centerMargin;
        leftStyle.maxWidth = '322px';
        leftStyle.width = '322px';
        leftStyle.paddingRight = props.data.content.left.tabletColumnStyling.centerMargin;
      } else {
        rightStyle.maxWidth = '644px';
        rightStyle.width = '644px';
        rightStyle.marginLeft = 'auto';
        rightStyle.marginRight = 'auto';
        leftStyle.maxWidth = '644px';
        leftStyle.width = '644px';
        leftStyle.marginLeft = 'auto';
        leftStyle.marginRight = 'auto';
      }
      if (props.data.flexDirection.tablet === 'column') {
        leftStyle.marginBottom = props.data.content.left.tabletColumnStyling.centerMargin;
        rightStyle.marginTop = props.data.content.left.tabletColumnStyling.centerMargin;
      } else if (props.data.flexDirection.tablet === 'column-reverse') {
        rightStyle.marginBottom = props.data.content.left.tabletColumnStyling.centerMargin;
        leftStyle.marginTop = props.data.content.left.tabletColumnStyling.centerMargin;
      }
      break;
    default: //desktop
      twoColumnStyle.flexDirection = props.data.flexDirection.desktop;
      props.data.desktopStyling ? (twoColumnStyle.marginTop = props.data.desktopStyling.marginTop) : (twoColumnStyle.marginTop = "0px");
      props.data.desktopStyling ? (twoColumnStyle.paddingBottom = props.data.desktopStyling.marginBottom) : (twoColumnStyle.paddingBottom = "0px");
      rightStyle.width = '660px';
      rightStyle.paddingLeft = props.data.content.right.desktopColumnStyling.centerMargin;
      leftStyle.width = '660px';
      leftStyle.paddingRight = props.data.content.left.desktopColumnStyling.centerMargin;
      break;
  }

  const bottomDivider = () => {
    if (props.data.bottomDivider !== "Yes") return;

    let dividerWidth;
    switch (props.device) {
      case 'mobile': dividerWidth = '315px'; break;
      case 'tablet': dividerWidth = '646px'; break;
      default: dividerWidth = '1316px'; break;
    }

    return (
      <hr
        className='bottomDivider'
        style={{
          margin: 'auto',
          borderTop: '1px',
          borderColor: '#707070',
          width: dividerWidth,
        }}
      ></hr>
    );
  }

  const centerDivider = () => {
    if ((props.device === 'mobile' && !props.data.mobileStyling.centerDivider) ||
      (props.device === 'tablet' && !props.data.tabletStyling.centerDivider) ||
      (props.device === 'desktop' && !props.data.desktopStyling.centerDivider)) {
      return <></>;
    }

    if (twoColumnStyle.flexDirection === ('row' || 'row-reverse')) {
      const verticalDivider = {
        backgroundColor: '#707070',
        width: '1px',
      }

      return <div style={verticalDivider} />;
    }

    let dividerWidth;
    switch (props.device) {
      case 'mobile':
        dividerWidth = '315px';
        break;
      case 'tablet':
        dividerWidth = '646px';
        break;
      default:
        dividerWidth = '1316px';
        break;
    }

    return (
      <hr
        className='bottomDivider'
        style={{
          flex: 'auto',
          margin: 'auto',
          borderTop: '1px',
          borderColor: '#707070',
          width: dividerWidth,
        }}
      ></hr>
    );
  }

  let leftAnalyticsModule = props.data.content.left.content.find(subcomponent => subcomponent.ref.includes('T')) || false;
  let rightAnalyticsModule = props.data.content.right.content.find(subcomponent => subcomponent.ref.includes('T')) || false;

  const renderColumn = (content, altAnalyticsModule) => {
    const subComponentProps = {
      data: content,
      key: content.ref,
      device: props.device,
      scrollTargetID: props.scrollTargetID,
      setScrollTargetID: props.setScrollTargetID,
      analyticsModule: props.analyticsModule,
      linkAnalyticsModule: (props.linkAnalytics ? props.linkAnalytics : altAnalyticsModule),
      refsArray: props.refsArray,
    };
    switch (content.type) {
      case 'text': return <Text {...subComponentProps} />;
      case 'image': return <Image {...subComponentProps} />;
      case 'button': return <Button {...subComponentProps} />;
      case 'appButtons': return <AppButtons {...subComponentProps} />;
      case 'video': return <Video {...subComponentProps} />;
      default: return;
    }
  };

  return (
    <>
      <div className='twoColumn' id={props.data.ref} style={twoColumnStyle} ref={currentRef ? currentRef.ref : null}>
        <div className={props.data.animate === 'none' ? 'leftSide' : `leftSide fade-in-left-section ${isVisible ? 'is-visible' : ''}`} style={leftStyle} ref={domRef}>
              {props.data.content.left.content.map((pop) => renderColumn(pop, (leftAnalyticsModule ? leftAnalyticsModule.text : rightAnalyticsModule.text)))}
          </div>
          {centerDivider()}
          <div className={props.data.animate === 'none' ? 'rightSide' : `rightSide fade-in-right-section ${isVisible ? 'is-visible' : ''}`} style={rightStyle} ref={domRef}>
              {props.data.content.right.content.map((pop) => renderColumn(pop, (rightAnalyticsModule ? rightAnalyticsModule.text : leftAnalyticsModule.text)))}
          </div>
      </div>
      {bottomDivider()}
    </>
  );
}

export default TwoColumn;

