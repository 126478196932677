
import { GlobalAnalytics } from '../../analytics';

const analytics = new GlobalAnalytics();

export const pageLoad = (data, page) => {
  analytics.firePageLoad({
    tag: data.tagId,
    app:  data.appId,
    section: data.section, 
    page: page
  });
};

export const videoAnalytics = (pageTitle, videoBeingPlayed, event, category, module, section, tag) => {
  analytics.fireOnClick({
      tag,
      section,
      page: pageTitle,
      category,
      contentTitle: videoBeingPlayed.title,
      mediaTitle: videoBeingPlayed.title,
      modelName: ' ',
      module,
      label: videoBeingPlayed.title,
      events: event,
  });
};

export const linkClick = (data) => {
    analytics.fireOnClick({
      tag: data.tagId,
      app: data.appId,
      section: data.section, 
      module: data.module,
      label: data.label
    });
  };

  const sendAnalytics = (tag, app, section, module, label) => {
      analytics.fireOnClick({
          tag,
          app,
          section,
          module,
          label,
      });
  };

export const moduleSubLoad = (data) => {
    data && sendAnalytics(
        data.tagId,
        data.appId,
        data.section,
        data.module,
        data.label
    );
};