import React, { useState, useEffect } from 'react';
import Hero from './components/hero.js';
import TwoColumn from './components/twoColumn';
import FourColumn from './components/fourColumn.js';
import Card from './components/card';
import ScrollToTopButton from './subComponents/scrollToTopButton.js';
import AppSettings from '../../appsettings.js';
import '../../styles/css/quickStartGuide/quickStartGuide.css';
import * as analytics from './quickStartGuide.analytics.js';

const imageSource = AppSettings.AWSImgRepo.quickStartGuide;

function QuickStartGuide(props) {
    const [device, setDevice] = useState('mobile');
    const [scrollTargetID, setScrollTargetID] = useState();

    const refIDs = props.data.map((data) => data.ref);
    const refsArray = refIDs.map(eachID => ({
        id: eachID, 
        ref: React.createRef(), 
        scroll: function scrollToRef(){this.ref.current.scrollIntoView({ behavior: 'smooth'})},
        visible: false,
        entry: null,
        exit: null,
        viewport: false
    }));
    useEffect(() => {
        const data = {
            tagId: '36.1',
            appId: 'eShow',
            section: 'Quick Start Guide'
        };
        analytics.pageLoad(data, 'Overview');
        updateDevice();
        window.addEventListener("resize", updateDevice);
        return () =>
            window.removeEventListener("resize", updateDevice);
    }, []);


    const updateDevice = () => {
        if (window.innerWidth >= 1320) {
            setDevice('desktop');
        } else if (window.innerWidth >= 768) {
            setDevice('tablet');
        } else if (window.innerWidth < 768) {
            setDevice('mobile');
        }
    }

    function renderComponent(content) {
        const componentProps = {
            data: content,
            key: content.ref,
            device: device,
            scrollTargetID: scrollTargetID,
            setScrollTargetID: setScrollTargetID,
            refsArray: refsArray,
            analytics: analytics
        };
        
        switch (content.type) {
            case "hero": 
                return (<Hero {...componentProps} />);
            case "twoColumn": 
                return (<TwoColumn {...componentProps} />);
            case "fourColumn":
                return (<FourColumn {...componentProps} />);
            case "card": 
                return (<Card {...componentProps} />);
            default: return;
        }
    }

    return (
        <div className={`quickStartGuide ${device}`}>
            <div id={`quickStartGuideLogo`}>
                <img alt="Toyota Engage Logo" src={`${imageSource}engageLogo.png`} />
            </div>
            <div className={`quickStartGuideContent`}>
                {props.data.map(item => renderComponent(item))}
            </div>
            <ScrollToTopButton device={device} />
        </div>
    );
}

export default QuickStartGuide;