import React from 'react';
import { videoAnalytics } from '../quickStartGuide.analytics';
import AppSettings from '../../../appsettings.js';
import '../../../styles/css/quickStartGuide/video.css';

function Video(props) {
    const style = {};
    let numericHeight;
    let playIconSize;

    switch (props.device) {
        case 'mobile':
            numericHeight = parseInt(props.data.mobileStyling.maxHeight);
            style.height = props.data.mobileStyling.maxHeight;
            style.width = Math.trunc((numericHeight * 1.792531120332)) + 'px';
            style.marginTop = props.data.mobileStyling.marginTop;
            playIconSize = 0;
            break;
        case 'tablet':
            numericHeight = parseInt(props.data.tabletStyling.maxHeight);
            style.height = props.data.tabletStyling.maxHeight;
            style.width = Math.trunc((numericHeight * 1.792531120332)) + 'px';
            style.marginTop = props.data.tabletStyling.marginTop;
            playIconSize = 161;
            break;
        default: // desktop
            numericHeight = parseInt(props.data.desktopStyling.maxHeight);
            style.height = props.data.desktopStyling.maxHeight;
            style.width = Math.trunc((numericHeight * 1.792531120332)) + 'px';
            style.marginTop = props.data.desktopStyling.marginTop;
            playIconSize = 347;
            break;
    }

    switch (props.data.align) {
        case 'left':
            style.marginLeft = '0';
            style.marginRight = 'auto';
            break;
        case 'right':
            style.marginLeft = 'auto';
            style.marginRight = '0';
            break;
        default:
            style.marginLeft = 'auto';
            style.marginRight = 'auto';
            break;
    }

    const widgetID = `${props.data.qumuID}qumu-widget`;
    // eslint-disable-next-line no-undef
    const widgetApi = KV.widget({
        guid: props.data.qumuID,
        type: 'playback',
        player: {
            width: parseInt(style.width),
            maxWidth: parseInt(style.width),
            height: parseInt(style.height),
            maxHeight: parseInt(style.height),
        },
        playIcon: {
            hide: false,
            url: AppSettings.AWSImgRepo.quickStartGuide + 'video-play-button.png',
            width: playIconSize,
            largeWidth: playIconSize,
            height: playIconSize,
            largeHeight: playIconSize,
            position: 'middle-center',
            padding: 0,
        },
        packshot: {
            width: parseInt(style.width),
            height: parseInt(style.height),
            fit: 'crop',
        },
        hideDownloadButton: true,
        hideEmbedButton: true,
        hideShareButton: true,
        showInfoPanel: false,
        infoLabels: false,
        sidebar: false,
        selector: `#${widgetID}`,
    }).api;

    widgetApi.init((err) => {
        if (err) {
            console.log(err);
        } else {
            const videoAnalyticsModule = props.analyticsModule ? props.analyticsModule : 'Video';
            const videoAnalyticsSection = props.analyticsSection ? props.analyticsSection : 'Quick Start Guide';
            const videoAnalyticsTag = props.analyticsTag ? props.analyticsTag : '40.5';
            const videoAnalyticsPage = props.page ? props.page : 'Overview';
            let sentAnalyticsForTwentyFive;
            let sentAnalyticsForFifty;
            let sentAnalyticsForSeventyFive;

            widgetApi.bind('play', () => {
                widgetApi.get('kulu', (kulu) => {
                    videoAnalytics(videoAnalyticsPage, kulu, 'event71', 'Video Play Click', videoAnalyticsModule, videoAnalyticsSection, videoAnalyticsTag, '0', true);
                });
            });

            widgetApi.bind('ended', () => {
                widgetApi.get('kulu', (kulu) => {
                    videoAnalytics(videoAnalyticsPage, kulu, 'event72', 'Video Complete', videoAnalyticsModule, videoAnalyticsSection, videoAnalyticsTag, '100');
                });
            });

            widgetApi.bind('timeupdate', () => {
                widgetApi.get('kulu', (kulu) => {
                    widgetApi.get('currentTime', (currentTime) => {
                        const percentWatched = Math.floor((currentTime / kulu.duration) * 100);
                        if (!sentAnalyticsForTwentyFive && percentWatched > 25) {
                            videoAnalytics(videoAnalyticsPage, kulu, 'event73', 'Video 25%', videoAnalyticsModule, videoAnalyticsSection, videoAnalyticsTag, '25');
                            sentAnalyticsForTwentyFive = true;
                        } else if (!sentAnalyticsForFifty && percentWatched > 50) {
                            videoAnalytics(videoAnalyticsPage, kulu, 'event74', 'Video 50%', videoAnalyticsModule, videoAnalyticsSection, videoAnalyticsTag, '50');
                            sentAnalyticsForFifty = true;
                        } else if (!sentAnalyticsForSeventyFive && percentWatched > 75) {
                            videoAnalytics(videoAnalyticsPage, kulu, 'event75', 'Video 75%', videoAnalyticsModule, videoAnalyticsSection, videoAnalyticsTag, '75');
                            sentAnalyticsForSeventyFive = true;
                        }
                    });
                });
            });
        }
    });

    return (
        <div style={style} className='video' >
            <div className="generic-qumu-widget">
                <div id={widgetID} />
            </div>
        </div>
    );
}

export default Video;
