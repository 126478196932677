/*global
    fireTag
*/

let config = {
    appId: 'eShow',
    section: "Quick Start Guide",
};

export class GlobalAnalytics {
    constructor() {
        this.pageLoadTag = "36.1";
        this.onClickTag = "36.3";
    }

    firePageLoad = function firePageLoad(params) {
        if (typeof fireTag !== 'undefined') {
            let body = {
                //User Analytics
                "<user_name>": sessionStorage ? sessionStorage.getItem('username') : "USERNAME",
                "<dealer_code>": sessionStorage ? sessionStorage.getItem('dealer_code') : "DEALER_CODE",
                "<full_name>": sessionStorage ? sessionStorage.getItem('full_name') : "FULL_NAME",
                "<user_type>": sessionStorage ? sessionStorage.getItem('user_type') : "USER_TYPE",
                "<region>": sessionStorage ? sessionStorage.getItem('region_code') : "REGION",
                "<position_title>": sessionStorage ? sessionStorage.getItem("position_title") : "POSITION_TITLE",
                "<job_code>": sessionStorage ? sessionStorage.getItem("job_code") : "JOB_CODE",
                "<spin_id>": sessionStorage ? sessionStorage.getItem("spin_id") : "SPIN_ID",
                "<linkType>": config.linkType,

                //Page Analytics
                "<app_id>": config.appId,
                "<site_size>": config.site_size,
                "<viewport>": config.winView,
                "<page>": params.page ? params.page : "<page> is undefined",
                "<section>": params.section ? params.section : "<section> is undefined",
                "<content_title>": params.contentTitle ? params.contentTitle : "<content_title> is undefined",
                "<media_title>": params.mediaTitle ? params.mediaTitle : "<media_title> is undefined",


                //Skills Specific
                "<category_topic>": params.categoryTopic ? params.categoryTopic : "<category_topic> is undefined",

                // Article Specific
                "<article_type>": params.articleType ? params.articleType : "<article_type> is undefined",

                // Search Specific
                "<description>": params.description ? params.description : "<description> is undefined",
                "<keyword>": params.keyword ? params.keyword : "<keyword> is undefined",
                "<number_of_search_results>": params.numberOfSearchResults ? params.numberOfSearchResults : "<number_of_search_results> is undefined"

            }
            let tag = params.tag ? params.tag : this.pageLoadTag;
            fireTag(tag, body);

        }
    }

    fireOnClick = function fireOnClick(params) {
        if (typeof fireTag !== 'undefined') {
            let positionTitle = sessionStorage ? sessionStorage.getItem("position_title") : "POSITION_TITLE";
            if (params.positionTitle) positionTitle = params.positionTitle;

            let body = {
                //User Analytics
                "<user_name>": sessionStorage ? sessionStorage.getItem('username') : "USERNAME",
                "<dealer_code>": sessionStorage ? sessionStorage.getItem('dealer_code') : "DEALER_CODE",
                "<full_name>": sessionStorage ? sessionStorage.getItem('full_name') : "FULL_NAME",
                "<user_type>": sessionStorage ? sessionStorage.getItem('user_type') : "USER_TYPE",
                "<region>": sessionStorage ? sessionStorage.getItem('region_code') : "REGION",
                "<position_title>": positionTitle,
                "<job_code>": sessionStorage ? sessionStorage.getItem("job_code") : "JOB_CODE",
                "<spin_id>": sessionStorage ? sessionStorage.getItem("spin_id") : "SPIN_ID",

                //Page Analytics
                "<app_id>": config.appId,
                "<viewport>": config.winView,
                "<site_size>": config.site_size,

                "<page>": params.page ? params.page : "<page> is undefined",
                "<section>": params.section ? params.section : "<section> is undefined",
                "<subsection>": params.subsection ? params.subsection : "<subsection> is undefined",
                "<technology_name>": params.technologyName ? params.technologyName : "<technologyName> is undefined",
                "<media_title>": params.mediaTitle ? params.mediaTitle : "<media_title> is undefined",
                "<category>": params.category ? params.category : "<category> is undefined",
                "<content_title>": params.contentTitle ? params.contentTitle : "<content_title> is undefined",
                "<container>": params.container ? params.container : "<container> is undefined",
                "<label>": params.label ? params.label : "<label> is undefined",
                "<model_year>": params.modelYear ? params.modelYear : "<model_year> is undefined",
                "<model_name>": params.modelName ? params.modelName : "<model_name> is undefined",
                "<trim>": params.trim ? params.trim : "<trim> is undefined",
                "<module>": params.module ? params.module : "<module> is not defined",
                // Skills Specific 
                "<category_topic>": params.categoryTopic ? params.categoryTopic : "<category_topic> is undefined",
                //Video Player Specific 
                "<events>": params.events ? params.events : "<events> is undefined",
                // Articles Specific 
                "<article_type>": params.articleType ? params.articleType : "<articleType> is undefined",
                "<tile_position>": params.tilePosition ? params.tilePosition : "",
                // Cert & Dropdown specific 
                "<action>": params.action ? params.action : "<action> is undefined",
                // Search Specific
                "<keyword>": params.keyword ? params.keyword : "<keyword> is undefined",
                "<number_of_search_results>": params.numberOfSearchResults ? params.numberOfSearchResults : "<number_of_search_results> is undefined"

            };

            let tag = params.tag ? params.tag : this.onClickTag;
            fireTag(tag, body);
        }
    }
}