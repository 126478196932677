import React, { useEffect, useState } from "react";
import QuickStartGuide from "./screens/quickStartGuide/quickStartGuide.js";
import "./styles/css/quickStartGuide/app.css";
import axios from "axios";
import AppSettings from "./appsettings.js";

function getFaviconEl() {
  return document.getElementById("favicon");
}

function App() {

  const[data, setData] = useState();

  function loadFavicon () {
    const favicon = getFaviconEl(); // Accessing favicon element
    favicon.href = AppSettings.AWSImgRepo.quickStartGuide + "favicon.ico";
  };


  useEffect(() => {
    axios.get(AppSettings.apiURL).then((response) =>{
      setData(response.data.Item.items);
    });
    loadFavicon();
  }, []);
  
  if(!data) return null;

  return (<div className="app">
      <QuickStartGuide data={data}></QuickStartGuide>
  </div>);
}
export default App;