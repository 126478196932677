import React from 'react';
import ReactFreezeframe from 'react-freezeframe';
import AppSettings from '../../../appsettings.js';

function Image(props) {
    let style = {};
    let imageAlt;
    let imageSource;

    switch (props.device) {
        case 'mobile':
            imageSource = AppSettings.AWSImgRepo.quickStartGuide + props.data.mobileStyling.image;
            imageAlt = props.data.mobileStyling.image;
            break;
        case 'tablet':
            imageSource = AppSettings.AWSImgRepo.quickStartGuide + props.data.tabletStyling.image;
            imageAlt = props.data.tabletStyling.image;
            break;
        default:
            imageSource = AppSettings.AWSImgRepo.quickStartGuide + props.data.desktopStyling.image;
            imageAlt = props.data.desktopStyling.image;
            break;
    }

    const getMarginLeft = () => {
        if ((props.device === 'mobile' && props.data.mobileStyling.align === 'center') ||
            (props.device === 'tablet' && props.data.tabletStyling.align === 'center') ||
            (props.device === 'desktop' && props.data.desktopStyling.align === 'center')) {
            return 'auto';
        } else if ((props.device === 'mobile' && props.data.mobileStyling.align === 'left') ||
                   (props.device === 'tablet' && props.data.tabletStyling.align === 'left') ||
                   (props.device === 'desktop' && props.data.desktopStyling.align === 'left')) {
            return '0';
        } else if ((props.device === 'mobile' && props.data.mobileStyling.align === 'right') ||
                   (props.device === 'tablet' && props.data.tabletStyling.align === 'right') ||
                   (props.device === 'desktop' && props.data.desktopStyling.align === 'right')) {
            return 'auto';
        }
        return;
    }

    const getMarginRight = () => {
        if ((props.device === 'mobile' && props.data.mobileStyling.align === 'center') ||
            (props.device === 'tablet' && props.data.tabletStyling.align === 'center') ||
            (props.device === 'desktop' && props.data.desktopStyling.align === 'center')) {
            return 'auto';
        } else if ((props.device === 'mobile' && props.data.mobileStyling.align === 'left') ||
                   (props.device === 'tablet' && props.data.tabletStyling.align === 'left') ||
                   (props.device === 'desktop' && props.data.desktopStyling.align === 'left')) {
            return 'auto';
        } else if ((props.device === 'mobile' && props.data.mobileStyling.align === 'right') ||
                   (props.device === 'tablet' && props.data.tabletStyling.align === 'right') ||
                   (props.device === 'desktop' && props.data.desktopStyling.align === 'right')) {
            return '0';
        }
        return;
    }

    if (props.device === 'mobile') {
        style = {
            display: 'block',
            marginLeft: getMarginLeft(),
            marginRight: getMarginRight(),
            maxHeight: props.data.mobileStyling.maxHeight,
            marginTop: props.data.mobileStyling.marginTop,

        };
    } else if (props.device === 'tablet') {
        style = {
            display: 'block',
            marginLeft: getMarginLeft(),
            marginRight: getMarginRight(),
            maxHeight: props.data.tabletStyling.maxHeight,
            marginTop: props.data.tabletStyling.marginTop,

        };
    } else {
        style = {
            display: 'block',
            marginLeft: getMarginLeft(),
            marginRight: getMarginRight(),
            maxHeight: props.data.desktopStyling.maxHeight,
            marginTop: props.data.desktopStyling.marginTop,

        };
    }

    const isGif = imageSource.endsWith('.gif');
    return isGif ?
        (<div style={style}><ReactFreezeframe alt={imageAlt} src={imageSource} /></div>) :
        (<img alt={imageAlt} style={style} src={imageSource} />);
}

export default Image;