import React from 'react';
import Text from '../subComponents/text.js';
import Image from '../subComponents/image.js';
import Button from '../subComponents/button.js';
import Video from '../subComponents/video.js';
import '../../../styles/css/quickStartGuide/card.css';

function Card(props){
    const currentRef = props.refsArray.find(items => items.id === props.data.ref);

    const renderLeft = () => {
        const leftStyle = {
            flex: "auto",
        };

        if (props.data.roundedCorners) {
            switch (props.device) {
                case 'mobile':
                    if (props.data.mobileBehavior === 'leftOnTop') {
                        leftStyle.borderTopLeftRadius = '16px';
                        leftStyle.borderTopRightRadius = '16px';
                    } else {
                        leftStyle.borderBottomLeftRadius = '16px';
                        leftStyle.borderBottomRightRadius = '16px';
                    }
                    break;
                case 'tablet':
                    if (props.data.tabletBehavior === 'leftOnTop') {
                        leftStyle.borderTopLeftRadius = '20px';
                        leftStyle.borderTopRightRadius = '20px';
                    } else {
                        leftStyle.borderBottomLeftRadius = '20px';
                        leftStyle.borderBottomRightRadius = '20px';
                    }
                    break;
                default:
                    leftStyle.borderTopLeftRadius = '20px';
                    leftStyle.borderBottomLeftRadius = '20px';
                    break;
            }
        }

        switch (props.device) {
            case 'mobile':
                leftStyle.backgroundColor = props.data.left.backgroundColor;
                leftStyle.height = props.data.left.mobileHeight;
                leftStyle.width = '100%';
                if (props.data.mobileBehavior === 'leftOnTop') {
                    leftStyle.alignSelf = 'flex-start';
                } else {
                    leftStyle.alignSelf = 'flex-end';
                }
                break;
            case 'tablet':
                leftStyle.backgroundColor = props.data.left.backgroundColor;
                leftStyle.height = props.data.left.tabletHeight;
                leftStyle.width = '100%';
                if (props.data.tabletBehavior === 'leftOnTop') {
                    leftStyle.alignSelf = 'flex-start';
                } else {
                    leftStyle.alignSelf = 'flex-end';
                }
                break;
            default:
                leftStyle.backgroundColor = props.data.left.backgroundColor;
                leftStyle.width = props.data.left.desktopWidth;
                leftStyle.alignSelf = 'flex-start';
                break;
        }

        return (
            <div className='cardLeft' style={leftStyle}>
                {props.data.left.content.map(item => renderSubComponent(item, (leftAnalyticsModule ? leftAnalyticsModule : rightAnalyticsModule)))}
            </div>
        );
    }

    const renderRight = () => {
        const rightStyle = {
            flex: "auto",
        };

        if (props.data.roundedCorners) {
            switch (props.device) {
                case 'mobile':
                    if (props.data.mobileBehavior === 'rightOnTop') {
                        rightStyle.borderTopLeftRadius = '16px';
                        rightStyle.borderTopRightRadius = '16px';
                    } else {
                        rightStyle.borderBottomLeftRadius = '16px';
                        rightStyle.borderBottomRightRadius = '16px';
                    }
                    break;
                case 'tablet':
                    if (props.data.tabletBehavior === 'rightOnTop') {
                        rightStyle.borderTopLeftRadius = '20px';
                        rightStyle.borderTopRightRadius = '20px';
                    } else {
                        rightStyle.borderBottomLeftRadius = '20px';
                        rightStyle.borderBottomRightRadius = '20px';
                    }
                    break;
                default:
                    rightStyle.borderTopRightRadius = '20px';
                    rightStyle.borderBottomRightRadius = '20px';
                    break;
            }
        }

        switch (props.device) {
            case 'mobile':
                rightStyle.backgroundColor = props.data.right.backgroundColor;
                rightStyle.height = props.data.right.mobileHeight;
                rightStyle.width = '100%';
                if (props.data.mobileBehavior === 'rightOnTop') {
                    rightStyle.alignSelf = 'flex-start';
                } else {
                    rightStyle.alignSelf = 'flex-end';
                }
                break;
            case 'tablet':
                rightStyle.backgroundColor = props.data.right.backgroundColor;
                rightStyle.height = props.data.right.tabletHeight;
                rightStyle.width = '100%';
                if (props.data.tabletBehavior === 'rightOnTop') {
                    rightStyle.alignSelf = 'flex-start';
                } else {
                    rightStyle.alignSelf = 'flex-end';
                }
                break;
            default:
                rightStyle.backgroundColor = props.data.right.backgroundColor;
                rightStyle.width = props.data.right.desktopWidth;
                rightStyle.alignSelf = 'flex-start';
                break;
        }

        return (
            <div className='cardRight' style={rightStyle}>
                {props.data.right.content.map(item => renderSubComponent(item, (rightAnalyticsModule ? rightAnalyticsModule : leftAnalyticsModule)))}
            </div>
        );
    }

    let leftAnalyticsModule = props.data.left.content.find(subcomponent => subcomponent.ref.includes('T')) || false;
    let rightAnalyticsModule = props.data.right.content.find(subcomponent => subcomponent.ref.includes('T')) || false;

    const renderSubComponent = (item, altAnalyticsModule) => {
        const subComponentProps = {
            data: item,
            key: item.ref,
            device: props.device,
            scrollTargetID: props.scrollTargetID,
            setScrollTargetID: props.setScrollTargetID,
            analyticsModule: props.analyticsModule,
            linkAnalyticsModule: (props.linkAnalytics ? props.linkAnalytics : altAnalyticsModule.text),
            refsArray: props.refsArray
        };
        switch (item.type) {
            case "text": return <Text {...subComponentProps} />;
            case "image": return <Image {...subComponentProps} />;
            case "button": return (<Button {...subComponentProps} />);
            case "video": return (<Video {...subComponentProps} />);
            default: return;
        }
    }

    
    const cardStyle = {
        display: "flex",
        backgroundColor: "black",
    };

    if (props.data.shadow) {
        cardStyle.boxShadow = '3px 3px 6px #00000029';
    }
    if (props.data.roundedCorners) {
        switch (props.device) {
            case 'mobile': cardStyle.borderRadius = '16px'; break;
            case 'tablet': cardStyle.borderRadius = '20px'; break;
            default: cardStyle.borderRadius = '20px'; break;
        }
    }

    switch (props.device) {
        case 'mobile':
            cardStyle.marginTop = props.data.mobileStyling.marginTop;
            cardStyle.width = '315px';
            if (props.data.tabletBehavior === 'leftOnTop') {
                cardStyle.flexDirection = 'column';
            } else {
                cardStyle.flexDirection = 'column-reverse';
            }
            break;
        case 'tablet':
            cardStyle.marginTop = props.data.tabletStyling.marginTop;
            cardStyle.width = '644px';
            if (props.data.tabletBehavior === 'leftOnTop') {
                cardStyle.flexDirection = 'column';
            } else {
                cardStyle.flexDirection = 'column-reverse';
            }
            break;
        default:
            cardStyle.marginTop = props.data.desktopStyling.marginTop;
            cardStyle.width = '1316px';
            cardStyle.flexDirection = 'row';
            break;
    }

    return (
        <div className='card' id={props.data.ref} style={cardStyle} ref={currentRef ? currentRef.ref : null}>
            {renderLeft()}
            {renderRight()}
        </div>
    );
}

export default Card;
