import React from 'react';
import AppSettings from '../../../appsettings.js';
import * as analytics from '../quickStartGuide.analytics.js';

function AppButtons(props) {
    const containerStyle = {
        display: 'flex',
        justifyContent: 'center'
    };
    const googleStyle = {};
    const appleStyle = {};

    const googleLink = 'https://play.google.com/store/apps/details?id=com.studiopmg.pocketsource';
    const appleLink = 'https://apps.apple.com/us/app/toyota-engage-app/id1324737565';
    
    const googleBadge = AppSettings.AWSImgRepo.quickStartGuide +  'google.png';
    const appleBadge = AppSettings.AWSImgRepo.quickStartGuide + 'apple.png';

    function getGoogleAnalyticsData () {
        return(
            {
                tagId: '36.3',
                appId: 'eShow',
                section: 'Quick Start Guide',
                module: props.analytics + " Module",
                label: "GET IT ON Google Play CTA",
            }
        );
    }

    function getAppleAnalyticsData () {
        return(
            {
                tagId: '36.3',
                appId: 'eShow',
                section: 'Quick Start Guide',
                module: props.analytics + " Module",
                label: "Download on the App Store CTA",
            }
        );
    }

    switch (props.device) {
        case 'mobile':
            containerStyle.marginTop = props.data.mobileStyling.marginTop;
            googleStyle.height = props.data.mobileStyling.buttonHeight;
            appleStyle.height = props.data.mobileStyling.buttonHeight;
            appleStyle.marginLeft = props.data.mobileStyling.buttonSpacing;
            break;
        case 'tablet':
            containerStyle.marginTop = props.data.tabletStyling.marginTop;
            googleStyle.height = props.data.tabletStyling.buttonHeight;
            appleStyle.height = props.data.tabletStyling.buttonHeight;
            appleStyle.marginLeft = props.data.tabletStyling.buttonSpacing;
            break;
        default: // 'desktop'
            containerStyle.marginTop = props.data.desktopStyling.marginTop;
            googleStyle.height = props.data.desktopStyling.buttonHeight;
            appleStyle.height = props.data.desktopStyling.buttonHeight;
            appleStyle.marginLeft = props.data.desktopStyling.buttonSpacing;
            break;
    }

    return (
        <div style={containerStyle} >
            <a href={googleLink} target='_blank' rel='noopener noreferrer' onClick={() => analytics.linkClick(getGoogleAnalyticsData())}>
                <img className='googleButton' src={googleBadge} alt='Get it on Google Play' style={googleStyle}  >
                    {/* Google Button */}
                </img>
            </a>
            <a href={appleLink}  target='_blank' rel='noopener noreferrer' onClick={() => analytics.linkClick(getAppleAnalyticsData())}>
                <img className='appleButton' src={appleBadge} alt='Download on the App Store' style={appleStyle} >
                    {/* Apple Button */}
                </img>
            </a>
        </div>
    );
}

export default AppButtons;